<template>
    <div class="order">
        <el-row v-if="artistList.length > 0" class="pb">
            <el-select class="pr" v-model="artistId" @change="loadDataList" placeholder="请选择">
                <el-option v-for="item in artistList" :key="item.id" :label="item.fullName" :value="item.id"> </el-option>
            </el-select>
        </el-row>

        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="artistFullName" label="分类" width="150" align="center"></el-table-column>
            <el-table-column prop="images" label="焦点图" width="160" align="center">
                <template slot-scope="scope">
                    <el-carousel height="150px" indicator-position="none">
                        <el-carousel-item v-for="(item, index) in getImages(scope.row)" :key="index">
                            <el-image style="height:150px;width:150px" :src="item | getImageUrl(CDN)" fit="contain"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                    <div>{{ getImages(scope.row).length }}&#8194;图</div>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="商品标题" header-align="center"></el-table-column>
            <el-table-column prop="price" label="价格" width="120" align="center" :formatter="formatter"></el-table-column>
            <el-table-column prop="amount" label="数量" width="120" align="center"></el-table-column>
            <el-table-column prop="state" label="上架售卖" width="100" align="center">
                <template slot-scope="scope">
                    <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.state" @change="toggleState(scope.row.id)" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="190" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick('showExt', scope.row)" type="text" size="small">查看详细</el-button>
                    <el-button @click="handleClick('edit', scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleClick('delete', scope.row)" type="text" size="small">删除</el-button>
                    <div>
                        <el-popover placement="right" width="100" trigger="hover">
                            <el-image style="width: 100px; height: 100px" :src="'https://artstore.lvchenkeji.com/mapi/sys/art/getItemQRCode?itemId=' + scope.row.id" :fit="fit"></el-image>
                            <el-button slot="reference" type="text" size="small">查看二维码</el-button>
                        </el-popover>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog v-if="item" :visible.sync="dialogVisible" title="详情">
            <table class="itemDetail" style="width:100%;border-left:#e8ebf3 1px solid;border-top:#e8ebf3 1px solid;">
                <tr>
                    <td style="width:50px;vertical-align:top" class="pb-1"><div>标题</div></td>
                    <td class="pb-1">
                        <div>{{ item.title }}</div>
                    </td>
                </tr>
                <tr>
                    <td style="width:50px;vertical-align:top"><div>简介</div></td>
                    <td>
                        <div>{{ item.introduce }}</div>
                    </td>
                </tr>
                <tr style="display:none">
                    <td style="width:50px;vertical-align:top" class="pt-1"><div>更多</div></td>
                    <td class="pt-1">
                        <div>{{ "材质：" + item.extCaizhi }}</div>
                        <div>{{ "形制：" + item.extXingzhi }}</div>
                        <div>{{ "年代：" + item.extNiandai }}</div>
                        <div>{{ "款式：" + item.extKuanshi }}</div>
                        <div>{{ "釿印：" + item.extJinyin }}</div>
                        <div>{{ "鉴藏印：" + item.extJiancangyin }}</div>
                        <div>{{ "题跋：" + item.extTiba }}</div>
                        <div>{{ "签条：" + item.extQiantiao }}</div>
                        <div>{{ "尺寸：" + item.extChicun }}</div>
                        <div>{{ "说明：" + item.extShuoming }}</div>
                    </td>
                </tr>
                <tr>
                    <td style="width:50px;vertical-align:top" class="pt-1"><div>详情</div></td>
                    <td class="pt-1" v-if="getDetails">
                        <template v-for="(item, index) in getDetails">
                            <el-image style="width:100%;display:block;" :src="item | getImageUrl(CDN)" :key="index" fit="contain"></el-image>
                        </template>
                    </td>
                    <td class="pt-1" v-else>
                        <div>空</div>
                    </td>
                </tr>
            </table>
        </el-dialog>
        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :page-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
            artistId: 0,
            artistList: [],
            dialogVisible: false,
            item: null,
            CDN: this.$cdn(),
        };
    },
    mounted() {
        let artistId = this.$route.query.artistId;
        if (artistId) {
            this.artistId = artistId;
        }
        this.loadArtist();
        this.loadDataList();
    },
    filters: {
        getImageUrl: function(val, cdn) {
            return cdn + val;
        },
    },
    computed: {
        getDetails: function() {
            if (this.item && this.item.details != "" && this.item.details != "[]") {
                return JSON.parse(this.item.details);
            }
            return null;
        },
    },
    methods: {
        formatter(row, column, value) {
            if (column.property == "price") {
                return "￥ " + value.toLocaleString();
            }
            return value;
        },
        getImages: function(row) {
            return JSON.parse(row.images);
        },
        loadArtist: function() {
            this.$http({
                url: "/sys/art/artistList",
                data: {
                    pageNo: 1,
                    pageSize: 100,
                },
            }).then((res) => {
                this.artistList = res.data.records;
                this.artistList.push({
                    id: 0,
                    fullName: "全部",
                });
            });
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/art/itemList",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    artistId: this.artistId,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        toggleState: function(id) {
            this.$http({
                url: "/sys/art/toggleItemState",
                data: {
                    id: id,
                },
            }).then((res) => {
                this.$message({
                    message: "操作成功",
                    type: "success",
                });
                this.loadDataList();
            });
        },
        handleClick: function(action, row) {
            // 删除商品
            if ("delete" == action) {
                this.$confirm("确定要删除当前商品吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http({
                            url: "/sys/art/deleteItem",
                            data: {
                                id: row.id,
                            },
                        }).then((res) => {
                            this.loadDataList();
                        });
                    })
                    .catch(() => {});
            }
            // 查看详情
            if ("showExt" == action) {
                this.item = row;
                this.dialogVisible = true;
            }
            // 编辑
            if ("edit" == action) {
                this.$router.push("/item/create?itemId=" + row.id);
            }
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style>
.el-dialog__body {
    padding: 0 10px 10px 10px;
}
.el-tooltip__popper {
    font-size: 14px;
    max-width: 300px;
}
.itemDetail td {
    border-right: #e8ebf3 1px solid;
    border-bottom: #e8ebf3 1px solid;
    padding: 5px;
}
.itemDetail td:first-child {
    font-weight: bold;
}
.itemDetail div {
    line-height: 130%;
    letter-spacing: 1px;
}
</style>
